@mixin bottomSheetConatiner {
    .mat-bottom-sheet-container {
        padding: 8px 0;
        border-radius: 16px;
        background: linear-gradient(180deg, #222230 0%, #22222D 100%);
        box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.50) inset;
        overflow: inherit;

        .drag-marker {
            width: 88px;
            height: 8px;
            border-radius: 20px;
            background: #2F2B3A;
            margin: 14px auto;
        }
    }
}

@mixin efb2bModalContainer {
    .modal-content {
        border-radius: 16px;
        background: #1C1827;
        box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.50) inset, 0px 14px 54px 0px rgba(0, 0, 0, 0.25);
        color: #fff;
        max-width: 600px;
        padding: 15px;
        margin: auto;
        max-height: calc(100vh - 272px);
        overflow: visible;
        // overflow-y: auto;

        .modal-header {
            border-bottom: 0;

            .modal-title {
                font-size: 20px;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 5px;

                &.highlight {
                    color: #FFAB48;
                }

                &.pr-success {
                    color: var(--Green, #3DD598);
                }
            }

            .sub-title {
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
                opacity: 0.7;
            }
        }

        .close-btn {
            position: absolute;
            right: 0;
            top: -40px;
        }

        .modal-body {

            .msg-section {
                height: 74px;

                display: flex;
                /* justify-content: space-evenly; */
                align-items: center;
                padding: 0 10px;

                /* &.warning {
                    background-image: url('../icons/practice/warning-bg.svg');
                    background-repeat: no-repeat;
                } */
            }

            .info-label {
                color: #FFF;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                opacity: 0.4;
            }

            .lms-content {
                border-radius: 14px;
                background: linear-gradient(180deg, #222230 0%, #22222D 100%);
                padding: 5px 15px;

                .type-list {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px 0;

                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(#FFFFFF, 0.2);
                    }

                    .q-mark-type {
                        font-weight: 400;
                        line-height: normal;
                        opacity: 0.7;
                    }
                }
            }

            .feedback-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .lms-checked-toggle-btn {
                    padding: 10px;
                }
            }

            .comment-section {
                textarea {
                    background-color: transparent;
                    color: #fff;
                }
            }
        }

        .modal-footer {
            border-top: 0;
            justify-content: flex-end;

            .ef-lms-btn {
                padding: 10px 40px;

                &.lms-btn-link {
                    background: var(--cta-text, #31313F);
                    font-size: 16px;

                    span {
                        /*  background: linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%);
                        -webkit-background-clip: text; */
                        color: #49E1FB;
                    }
                }
            }
        }
    }
}