// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ef-b2b-portal-primary: mat.define-palette(mat.$indigo-palette);
$ef-b2b-portal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ef-b2b-portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ef-b2b-portal-theme: mat.define-light-theme(
  (
    color: (
      primary: $ef-b2b-portal-primary,
      accent: $ef-b2b-portal-accent,
      warn: $ef-b2b-portal-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ef-b2b-portal-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

@import "./assets/scss/login.scss";
@import "./assets/scss/mixins.scss";
@import ".././src/assets/scss/variables.scss";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;700;800;900&display=swap");

//"@angular/material/prebuilt-themes/indigo-pink.css",

* {
  margin: 0;
  padding: 0;
  // font-family: 'Poppins', sans-serif;
}

body {
  background-color: #09070e;
  color: #fff;
  height: calc(100vh - 72px);
  overflow-x: hidden;
}

body,
html {
  @include transition(all 0.5s);
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  height: 100%;
}

body [class^="mat"],
body [class*=" mat-"],
body [class^="mdc"] {
  font-family: "Poppins", sans-serif !important;
}

body [class*="material-icons"] {
  font-family: "Material Icons Two Tone" !important;
}

/** Scroll Bar */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #09070e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #191924;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #222230 0%, #22222d 100%);
}

/** B2B Portal Button */
.btn {
  &.ef-b2b-primary-btn {
    border-radius: 8px;
    background: var(--CTA, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));

    color: #31313f;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    // text-transform: uppercase;

    padding: 8px 16px;

    &:hover {
      color: initial;
    }
  }

  &.ef-b2b-secondary-btn {
    border-radius: 8px;
    background: linear-gradient(180deg, #222230 0%, #22222d 100%);

    padding: 8px 16px;

    span {
      background: var(
        --Brand-Primary,
        linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }

    &:hover {
      color: initial;
    }
  }

  &.ef-b2b-link-btn {
    color: #49e1fb;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      color: #49e1fb;
    }

    &:active {
      color: #49e1fb !important;
      border-color: transparent !important;
      background-color: transparent !important;
    }
  }

  &:disabled {
    border: 0;
    opacity: 0.2;
  }
}

.ef-b2b-link-btn.back-btn {
  color: #96a7af;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  padding-left: 0;

  &:active {
    color: inherit !important;
  }
  &:hover {
    color: inherit !important;
  }
}

:not(.btn-check) + .btn:active,
.btn:first-child:active {
  color: initial;
  background-color: initial;
  border-color: initial;
}

/** B2B Portal Button End */

/** B2B form control */

.b2b-form-ctrl-wrapper {
  .form-label {
    color: #8f8f94;
    font-weight: 400;
    line-height: normal;
  }

  .form-control {
    background-color: transparent;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    height: 52px;

    &::placeholder {
      color: #94939b;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }

  .form-select {
    background-color: transparent;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    --bs-form-select-bg-img: url("./assets/icons/down-arrow-icon.svg");
    height: 52px;

    option {
      border-radius: 5px;
      border: 1px solid #474752;
      background: #181822;
      box-shadow: 0px 3px 9px 0px rgba(64, 77, 79, 0.25);
    }
  }

  .b2b-select-box-placeholder {
    position: absolute;
    left: 15px;
    top: 15px;
    color: #8f8f94;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: default;
  }

  .input-group {
    .input-group-text {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-right: 0;
      border-radius: 12px;
      color: #fff;
    }

    .form-control {
      border-left: 0;
      margin-left: 0 !important;
    }
  }
}

.ef-b2b-add-btn {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #49e1fb;
  color: #49e1fb;
}

.cursorPointer {
  cursor: pointer;
}

/** B2B form control end */

.header-block {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 15px 0;
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);

  .page-caption {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    opacity: 0.7;
  }
}

.photo-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    border-radius: 8.022px;
    background: #d9d9d9;
    padding: 6px;
  }

  .img-info-block {
    .lbl {
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }

    .size-info {
      color: #8f8f94;
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
    }

    .ef-b2b-link-btn {
      font-size: 16px;
    }
  }
}

.section-heading {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.required {
  color: #f00;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ef-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
  margin-right: 8px !important;
}

.b2b-error-text {
  color: red;
  font-size: 12px;
}

.b2b-empty-container {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  background: #22222e;
  padding: 42px 0;

  .b2b-empty-wrapper {
    width: 241px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .scr-lbl {
      color: var(--ffffff, #fff);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }

    .scr-info {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      // font-weight: 600;
      line-height: normal;
      opacity: 0.4;
    }

    .ef-b2b-primary-btn {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
      text-transform: none;
    }
  }
}

.lms-radio-toggle-btn {
  margin-right: 15px;

  label {
    border-radius: 12px;
    background: #22222f;

    color: #fff;
    text-align: center;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 20px;

    span {
      white-space: nowrap;
      opacity: 0.4;
    }
  }

  .btn-check + .btn:hover {
    color: inherit;
    background-color: rgba($color: #fff, $alpha: 0.3);
    border-color: transparent;
  }

  .btn-check:checked + .btn {
    border: 1px solid #49e1fb;
    /*   background: linear-gradient(180deg, #22222F 0%, #22222F 100%), radial-gradient(circle at top right, #27D4FA, #7AF4FC);
          background-origin: border-box;
          background-clip: padding-box, border-box; */

    span {
      /*   background: linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%); */
      /* -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text; */
      color: #49e1fb;

      opacity: 1;
    }
  }
}

/** 
* Modal Dialog
*/
.ef-b2b-dialog-container {
  @include efb2bModalContainer;
  overflow-y: scroll;

  .modal-footer {
    .btn {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.modal-backdrop {
  opacity: 0.8 !important;
}

/**
* Material Tab
*/
.mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-link .mdc-tab__text-label {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.7;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active
  .mdc-tab__text-label {
  color: #49e1fb;
  opacity: 1;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled)
  .mdc-tab-indicator__content--underline {
  border-color: #474752;
  opacity: 1;
}

.mdc-tab-indicator--active .mdc-tab-indicator__content {
  border-color: #49e1fb !important;
}

.mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
  display: none !important;
}

/** mat tab end */
.lms-checked-toggle-btn {
  margin-right: 15px;

  label {
    border-radius: 12px;
    background: #22222f;

    color: #fff;
    text-align: center;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 20px;

    span {
      white-space: nowrap;
      opacity: 0.4;
    }
  }

  .btn-check + .btn:hover {
    color: inherit;
    background-color: rgba($color: #fff, $alpha: 0.3);
    border-color: transparent;
  }

  .btn-check:checked + .btn {
    border: 1px solid transparent;
    background: linear-gradient(180deg, #22222f 0%, #22222f 100%),
      radial-gradient(circle at top right, #27d4fa, #7af4fc);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    span {
      /*    background: linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text; */
      color: #49e1fb;
      opacity: 1;
    }
  }
}

.lms-radio-toggle-btn {
  margin-right: 15px;

  label {
    border-radius: 12px;
    background: #22222f;

    color: #fff;
    text-align: center;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 20px;

    span {
      white-space: nowrap;
      opacity: 0.4;
    }
  }

  .btn-check + .btn:hover {
    color: inherit;
    background-color: rgba($color: #fff, $alpha: 0.3);
    border-color: transparent;
  }

  .btn-check:checked + .btn {
    border: 1px solid #49e1fb;

    span {
      color: #49e1fb;
      opacity: 1;
    }

    &:hover {
      background-color: rgba($color: #fff, $alpha: 0.1);
    }
  }
}

.comment-section {
  .form-control {
    border: 1px solid #535156;
  }
}

@include media-breakpoint-up(lg) {
  .comment-section {
    display: flex;
    justify-content: center;

    .form-control {
      width: 370px;
    }
  }
}

.ef-lms-btn {
  border-radius: 9px;
  background: var(--ctabg, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));
  // background: var(--ctabg, linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%));

  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border: 0px;
  padding: 10px 20px;

  &:hover {
    color: initial;
  }

  &:disabled {
    color: #fff;
    border: 0;
    background: #22222f;

    &span {
      opacity: 0.4;
    }
  }

  &.lms-btn-link {
    /* background: linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text; */
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    z-index: 1;
    color: #49e1fb;
    background: transparent;
  }

  &.lms-btn-link.danger {
    color: #fa2739;
  }
}

/**
* Modal content
*/

.b2b-modal-list-container {
  margin-bottom: 15px;
  overflow-y: auto;

  .b2b-modal-radio-item {
    border-radius: 12px;
    background: #22222f;
    padding: 20px;
    margin-bottom: 10px;

    .form-check-input {
      margin-left: -6px;
      background: transparent;
      border: 0;
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: -1px;
        background: url("./assets/icons/radio.svg");
        background-size: 14px 14px;
        width: 14px;
        height: 14px;
      }

      &:checked {
        border-color: transparent;
        // background: #fff;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: -1px;
          background: url("./assets/icons/checked-radio.svg");
          background-size: 14px 14px;
          width: 14px;
          height: 14px;
        }
      }
    }

    label {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

/**
* Materila Menu override
*/
.ef-b2b-teacher-menu {
  &.mat-mdc-menu-panel {
    border-radius: 12px;
    background: #1c1827;
    box-shadow: 3px 4px 8px 0px rgba(104, 104, 104, 0.14);
  }
}

.profleBtn {
  border-radius: 4px;
  background: var(--CTA-BG, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));
  color: #31313f;
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 3px 10px;
  border-radius: 6px;
  margin-top: 6px;
  border: 0px;
  margin-left: 86px;
}

@media only screen and (max-width: 768px) {
  .question-state-bottom-panel-wrapper .cdk-dialog-container {
    background-color: #1c1827 !important;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.5) inset;
    border-radius: 16px;
    overflow: inherit;
  }
}

.question-state-bottom-panel-wrapper .container-fluid.rating-container {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
}

//   .custom-primary .mat-progress-bar-fill {
//     background-color: #3DD598; /* Green */
//   }

//   .custom-accent .mat-progress-bar-fill {
//     background-color: #FDD649; /* Yellow */
//   }

//   .custom-warn .mat-progress-bar-fill  {
//     background-color: #F26D0C; /* Red */
//   }

//   .custom-low .mat-progress-bar-fill {
//     background-color: #9e9e9e; /* Grey */
//   }
