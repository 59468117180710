.login-pf-body {
  font-family: "Poppins", sans-serif;
  color: #fff;
  // background: url(../login-bg.png) 50% 0px / auto no-repeat rgb(3, 3, 3);

  background: url(../login-bg.png) no-repeat rgb(3, 3, 3);
  background-size: cover;
}

.login-box-title {
  color: #fff;

  font-family: "Poppins";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  /* 156.522% */

  p {
    color: #8f8f94;
    font-family: "Poppins";
    font-size: 16px !important;
    font-style: normal;
    line-height: 17px;
    font-weight: 300;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: 25px;
  }
}

.loginP {
  color: #8f8f94;
  font-family: "Poppins";
  font-size: 16px !important;
  font-style: normal;
  line-height: 17px;
  font-weight: 300;
  line-height: normal;
  margin-top: 7px !important;
}

.enterotptx {
  padding-bottom: 0px;
  margin-bottom: 0px !important;
}

.back-to-login {
  color: #96a7af !important;
  text-decoration: none;
  display: inline-block;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  line-height: normal;
}

.login-form-page #kc-content {
  width: 470px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0px;
  top: 0px;
  padding-right: 35px !important;
  background: rgb(25, 25, 33);
}

.login-form-page .login-form-conatiner {
  box-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px 0px inset;
  min-width: 97%;
  z-index: 444;
  position: relative;
  border-radius: 16px;
  background: rgb(34, 34, 46);
  padding: 35px;
}

.login-form-page .caption {
  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 5px;
}

.login-form-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 25px;
}

.login-form-page .caption {
  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 5px;
}

// .login-form-page .caption span {
//     background: var(--ctabg, linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%));
//     background-clip: text;
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     font-feature-settings: 'clig' off, 'liga' off;
// }

.login-form-page .sub-caption {
  color: #8f8f94;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
}

.login-form-page .form-control {
  background-color: transparent;
  border-radius: 12px !important;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  color: #94939b;
  font-size: 16px;
  font-weight: 400;
  height: inherit !important;
  line-height: normal;
}

.pf-m-error {
  font-size: 12px;
  color: #ff575f;
}

.login-form-page .form-control[aria-invalid="true"] {
  border: 1px solid #ff575f;
}

.login-form-page .form-control::placeholder {
  font-style: normal !important;
}

.login-form-page .form-control:focus {
  box-shadow: 0 0 0 0.25rem #27d4fa40;
}

.login-form-page ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #94939b;
  opacity: 1;
  /* Firefox */
}

.login-form-page .btn-link {
  padding-left: 0;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  background: var(--ctabg, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  outline: none !important;
}

.login-form-conatiner .wa-update-container {
  cursor: pointer;
}

.login-form-page .form-check-input:focus {
  border-color: #27d4fa;
  box-shadow: 0 0 0 0.25rem #27d4fa40;
}

/* .login-form-page .form-check-input:checked {
    background-image: url('./assets/check-icon.svg');
} */

.login-form-page .form-check-label {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.login-form-page .tnc-txt {
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  opacity: 0.7;
}

.login-form-page .tnc-txt.enterotptext {
  text-align: left;
}

.login-form-page .tnc-txt span {
  background: var(--ctabg, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login-no-bg-action {
  background: linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 14px;
  border: 0px !important;
  text-transform: uppercase;
  padding-right: 0;
  font-family: "Poppins", sans-serif !important;
}

.login-btn {
  color: var(--cta-text, #31313f);
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;

  border-radius: 12px;
  background: var(--ctabg, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));
  width: 100%;

  padding: 15px;
}

.digit-group input {
  width: 28px;
  height: 37px;
  background-color: transparent;
  border: none;
  line-height: 50px;
  text-align: center;
  font-size: 22px;
  font-weight: 200;
  color: white;
  margin: 0 9px;
  border: 0px;
  border-bottom: 2px solid #fff !important;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: none !important;
  outline: none;
}

.digit-group input:first-child {
  margin-left: 0px !important;
}

.digit-group input:last-child {
  margin-left: 0px !important;
}

#phoneNumber {
  background: url(../indian-flag.png) 10px center no-repeat;
  padding-left: 78px;
  color: #94939b;
}

.inboxcheckbox {
  padding-left: 0;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  background: var(--ctabg, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));
  background-clip: text;
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  outline: none !important;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.phonenumberwrap {
  position: relative;
}

.phonenumberposition {
  position: absolute;
  left: 44px;
  top: 15px;
  font-size: 16px;
}

.getotptop {
  padding-top: 50px;
}

.backtomobile {
  margin: 0px !important;
  padding: 0px;
  border: 0px;
  outline: none;
  color: #96a7af;
  font-size: 16px;
  background: none;
  background: none !important;
}

.counttx {
  font-size: 12px;
  color: #fff;
  opacity: 0.8;
}

.editnumber {
  text-align: right;
  float: right;
  width: 50%;
  text-align: right;
}

.resentotpwrapper {
  overflow: hidden;
  margin-top: 27px !important;
  margin-bottom: 37px !important;
}

.enterOTP {
  font-size: 23px !important;
  margin-bottom: 16px !important;
}

.resentotp {
  float: left;
  width: 50%;
}

.editnumber {
  text-align: right;
  float: right;
  width: 50%;
  text-align: right;
}

.registerbottom {
  margin-bottom: 38px !important;
}

.btn-link:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.alert-error {
  border-radius: 4px;
  border: 1px solid #ffab48;
  background: rgba(255, 171, 72, 0.2);
}

@media only screen and (min-width: 992px) {
  .login-form-page {
    justify-content: flex-start;
  }

  .login-form-page .login-form-conatiner {
    border-radius: 16px;
    background: #22222e;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.5) inset;
    min-width: 97%;

    padding: 35px;
    z-index: 444;
    position: relative;
  }

  .login-form-page #kc-content {
    padding-right: 35px !important;
    width: 470px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0px;
    top: 0px;
    background: #191921;
  }
}

@media only screen and (max-width: 991px) {
  .login-form-page .caption {
    margin-bottom: 23px !important;
  }

  .registerbottom {
    margin-bottom: 23px !important;
  }

  .login-form-page {
    justify-content: flex-start;
  }

  .login-form-page .login-form-conatiner {
    border-radius: 16px;
    background: #22222e;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.5) inset;
    min-width: 97%;

    padding: 35px;
    z-index: 444;
    position: relative;
    margin-right: 11px;
    margin-bottom: 10px;
  }

  .login-form-page #kc-content {
    padding-right: 0px !important;
    width: 470px;
    height: 100vh;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0px;
    top: 0px;
    background: #19192150;
  }

  .getotptop {
    padding-top: 20px;
  }
}

.welcomeMobile {
  display: none;
}

.login {
  color: #fff;
  font-family: "Poppins";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.loginlogo {
  display: none;
}

.otpText {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 137.5% */
}

@media only screen and (max-width: 767px) {
  .login-form-page .login-form-conatiner {
    margin-right: 0px;
    margin-bottom: 60px;
    background: var(--BG, #09070e);
    border-radius: unset;
    box-shadow: none;
    padding: 30px;
    // padding-bottom: 0px;
  }

  .login-form-page #kc-content {
    padding-left: 0px !important;
    background: var(--BG, #09070e);
  }

  .login-pf-body {
    background: var(--BG, #09070e);
  }

  .login {
    display: none;
  }

  .welcomeMobile {
    display: inherit;
    color: #fff;
    font-family: "Poppins";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }

  .loginlogo {
    display: inherit;
    width: 100px;
    height: 101.834px;
    flex-shrink: 0;
  }
}

.backbutton {
  padding-right: 10px;
}

.noBorder {
  border: 0px !important;
  background: linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 14px;
  border: 0px !important;
  padding-right: 0;
  font-family: "Poppins", sans-serif !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  color: white;
}

.phonenumberpositionMobile {
  position: absolute;
  left: 44px;
  top: 7px;
  color: white;
  font-size: 16px;
}

.examFactorText {
  color: #27d4fa;
}
